import React from 'react';
import styled from 'styled-components';
import ContactButton from './ContactButton';
import { graphql, useStaticQuery } from 'gatsby';

const Section = styled.section`
  margin: 100px auto 100px auto;
  padding: 0 15px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
  }

  @media (min-width: ${({ theme }) => theme.desktop}) {
    padding: 0;
  }

  &.nonMargin {
    margin: 0 0 30px 0;
    padding: 0;
    max-width: unset;
  }
`;

const Headline = styled.h1`
  font-size: ${({ theme }) => theme.size24};
  font-weight: ${({ theme }) => theme.semiBold};
  line-height: 130%;
  letter-spacing: 0%;
  margin-bottom: 20px;
  text-align: center;
`;

const Text = styled.p`
  font-size: ${({ theme }) => theme.size14};
  font-weight: ${({ theme }) => theme.light};
`;

const ContactTextInfo = styled.p`
  font-size: ${({ theme }) => theme.size15};
  font-weight: ${({ theme }) => theme.medium};
  text-align: center;
  margin-bottom: 20px;
  margin-top: 30px;

  @media (min-width: ${({ theme }) => theme.laptop}) {
    font-size: ${({ theme }) => theme.size16};
  }
`;

const HowCooperateWorks = ({ nonMargin }) => {
  const cms = useStaticQuery(query);
  return (
    <Section className={nonMargin && 'nonMargin'}>
      <Headline>
        {/* Jak wygląda współpraca? */}
        {cms.datoCmsHowCooperateWork.title}
      </Headline>
      <Text
        dangerouslySetInnerHTML={{ __html: cms.datoCmsHowCooperateWork.text }}
      />
      {/* <strong>Na początku chcielibyśmy Cię poznać</strong> i w tym celu
        zapraszamy na spotkanie. Tak aby najlepiej zbadać Twoje oczekiwania i
        najtrafniej ocenić Twoje potrzeby.
        <br />
        <strong>Na spotkaniu</strong> chcielibyśmy zapoznać się z tym na czym
        najbardziej Ci zależy. Jaki kapitał chciałbyś zaangażować i czy masz
        czas na inwestowanie, a może raczej chciałbyś żebyśmy się tym zajęli za
        Ciebie.
        <br />
        <strong>Sposób współpracy</strong>, w praktyce tworzymy dla Ciebie
        spersonalizowaną taktykę inwestycyjną. Wyszukamy dla Ciebie okazję
        inwestycyjną i zastępujemy Cię lub możemy zastąpić na każdym etapie
        procesu inwestycyjnego.
        <br />
        <strong>Wsparcie zespołu specjalistów</strong> – dokonujemy analizę
        inwestycyjną zysków, prawnicy zadbają o opiekę prawną, profesjonalny
        zespół remontowy wraz ze wsparciem logistycznym i projektowym poprowadzi
        całość procesu remontowo-wykończeniowego.
        <br />
        <strong>Gwarancja uzyskanych zysków</strong> – obsługując najem Twojej
        nieruchomości staramy się stale maksymalizować uzyskiwany przez Ciebie
        zysk. Twoje mieszkanie jest dobrze oceniane. Dzięki zawieranym
        profesjonalnym umowom najemcy znają swoje praw i obowiązki. Ekipy
        remontowe szybko naprawiają powstałe awarie, a zarządcy sprawują nadzór
        nad stanem wynajmowanej nieruchomości.
        <br />
        <strong>Długotrwałe relacje</strong> – dbamy o relacje, dlatego będąc
        naszym partnerem zawsze możesz do Nas zadzwonić, zapytać o poradę, a My
        dołożymy wszelkich starań żeby Ci pomóc. */}
      <ContactTextInfo
        dangerouslySetInnerHTML={{
          __html: cms.datoCmsHowCooperateWork.contactInformation,
        }}
      />
      {/* Skontaktuj się z nami, a odpowiemy na wszystkie Twoje pytania */}
      <ContactButton />
    </Section>
  );
};

const query = graphql`
  query HowCooperateWorksQuery {
    datoCmsHowCooperateWork {
      title
      text
      contactInformation
    }
  }
`;

export default HowCooperateWorks;
