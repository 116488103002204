import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Button = styled(Link)`
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.yellow};
  color: white;
  width: 160px;
  height: 48px;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.size20};
  font-weight: ${({ theme }) => theme.semiBold};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContactButton = () => {
  return <Button to='/kontakt'>Kontakt</Button>;
};

export default ContactButton;
