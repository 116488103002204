import React from 'react';
import styled from 'styled-components';

// Icons import
import arrow1 from '../../assets/images/arrow1.png';
import arrow2 from '../../assets/images/arrow2.png';
import arrow3 from '../../assets/images/arrow3.png';
import arrow4 from '../../assets/images/arrow4.png';
import arrow5 from '../../assets/images/arrow5.png';
import arrow6 from '../../assets/images/arrow6.png';

const Wrapper = styled.section`
  margin: 100px auto 100px auto;
  padding: 0 15px;
  max-width: 1200px;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* @media (min-width: ${({ theme }) => theme.laptop}) {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    grid-template-rows: repeat(6, 1fr);
  } */

  @media (min-width: ${({ theme }) => theme.desktop}) {
    padding: 0;
  }
`;

const Container = styled.div`
  width: 100%;
  margin-top: 50px;
  /* @media (min-width: ${({ theme }) => theme.tablet}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  } */

  @media (min-width: ${({ theme }) => theme.tablet}) {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    grid-template-rows: repeat(6, 1fr);
  }
`;

const Box = styled.div`
  width: 100%;
  height: 50px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  padding: 10px;
  font-size: ${({ theme }) => theme.size12};
  position: relative;
  @media (max-width: ${({ theme }) => theme.tablet}) {
    &:not(:last-child) {
      &::before {
        content: url(${arrow1});
        position: absolute;
        bottom: 0;
        left: 50%;
        scale: 0.35;
        transform-origin: left;
        transform: translate(-40%, 155%) rotate(90deg);
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.tablet}) {
    max-width: 45%;
    height: 80px;
    margin-bottom: 0;
  }

  @media (min-width: ${({ theme }) => theme.tablet}) {
    max-width: unset;
    height: 100%;

    &:nth-of-type(1) {
      grid-area: 1/1/2/3;
      &::before {
        content: url(${arrow1});
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(160%, -50%) scale(0.7);
      }
    }
    &:nth-of-type(2) {
      grid-area: 1/4/2/6;
      transform: translateY(-20px);

      &::before {
        content: url(${arrow2});
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(140%, -10%) scale(0.7);
      }
    }
    &:nth-of-type(3) {
      grid-area: 1/7/2/9;

      &::before {
        content: url(${arrow3});
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(140%, 20%) scale(0.7);
      }
    }
    &:nth-of-type(4) {
      grid-area: 2/10/3/12;
      transform: translateY(20px);

      &::before {
        content: url(${arrow4});
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-140%, 40%) scale(0.7);
      }
    }
    &:nth-of-type(5) {
      grid-area: 3/6/4/8;
      transform: translate(40px, 20px);

      &::before {
        content: url(${arrow5});
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-130%, -10%) scale(0.6);
      }
    }
    &:nth-of-type(6) {
      grid-area: 3/3/4/4;
      transform: translateX(20px);

      &::before {
        content: url(${arrow6});
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(70%, 50%) scale(0.5);
      }
    }
    &:nth-of-type(7) {
      grid-area: 5/5/6/7;
    }
  }

  @media (min-width: ${({ theme }) => theme.desktop}) {
    max-width: unset;
    height: 100%;
    font-size: ${({ theme }) => theme.size14};
    &:nth-of-type(1) {
      grid-area: 1/1/2/3;
      &::before {
        content: url(${arrow1});
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(160%, -50%);
      }
    }
    &:nth-of-type(2) {
      grid-area: 1/4/2/6;
      transform: translateY(-20px);

      &::before {
        content: url(${arrow2});
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(140%, -10%);
      }
    }
    &:nth-of-type(3) {
      grid-area: 1/7/2/9;

      &::before {
        content: url(${arrow3});
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(140%, 20%);
      }
    }
    &:nth-of-type(4) {
      grid-area: 2/10/3/12;
      transform: translateY(20px);

      &::before {
        content: url(${arrow4});
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-140%, 40%);
      }
    }
    &:nth-of-type(5) {
      grid-area: 3/6/4/8;
      transform: translate(40px, 20px);

      &::before {
        content: url(${arrow5});
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-170%, -10%);
      }
    }
    &:nth-of-type(6) {
      grid-area: 3/3/4/4;
      transform: translateX(20px);

      &::before {
        content: url(${arrow6});
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(90%, 90%);
      }
    }
    &:nth-of-type(7) {
      grid-area: 6/5/7/7;
    }
  }
`;

const Headline = styled.h1`
  font-size: ${({ theme }) => theme.size24};
  font-weight: ${({ theme }) => theme.semiBold};
  line-height: 130%;
  letter-spacing: 0%;
  margin-bottom: 20px;
  text-align: center;
`;

const Roadmap = ({ cmsData }) => {
  return (
    <Wrapper>
      <Headline>
        {/* Jak tworzymy mieszkaniowe produkty inwestycyjne? */}
        {cmsData.datoCmsApartmentInvestProductNewpage.roadmapTitle}
      </Headline>
      <Container>
        <Box>
          {/* Prosty model inwestycyjny */}
          {cmsData.datoCmsApartmentInvestProductNewpage.roadmapFirst}
        </Box>
        <Box>
          {/* Wyszukanie mieszkania z potencjałem */}
          {cmsData.datoCmsApartmentInvestProductNewpage.roadmapSecond}
        </Box>
        <Box>
          {/* Przejrzysta analiza inwestycji */}
          {cmsData.datoCmsApartmentInvestProductNewpage.roadmapThird}
        </Box>
        <Box>
          {/* Opieka prawna i podatkowa */}
          {cmsData.datoCmsApartmentInvestProductNewpage.roadmapFourth}
        </Box>
        <Box>
          {/* Zakup mieszkania */}
          {cmsData.datoCmsApartmentInvestProductNewpage.roadmapFifth}
        </Box>
        <Box>
          {/* Dostosowanie mieszkania do maksymalizacji zysków
          (przearanżowanie/remont) */}
          {cmsData.datoCmsApartmentInvestProductNewpage.roadmapSixth}
        </Box>
        <Box>
          {/* Zarabiaj na wynajmie */}
          {cmsData.datoCmsApartmentInvestProductNewpage.roadmapSeventh}
        </Box>
      </Container>
    </Wrapper>
  );
};

export default Roadmap;
