import React from 'react';
import styled from 'styled-components';
import Roadmap from '../../components/atoms/Roadmap';
import SubpageHeader from '../../components/organisms/SubpageHeader';
import MainTemplate from '../../templates/MainTemplate';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import ContactButton from '../../components/atoms/ContactButton';
import TheyWorkWithUs from '../../components/organisms/TheyWorkWithUs';
import HowCooperateWorks from '../../components/atoms/HowCooperateWorks';

const FirstSection = styled.section`
  margin: 50px auto 100px auto;
  padding: 0 15px;
  max-width: 1200px;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
  }

  @media (min-width: ${({ theme }) => theme.smLaptop}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (min-width: ${({ theme }) => theme.desktop}) {
    padding: 0;
  }
`;

const FirstSectionLeftContainer = styled.div`
  @media (min-width: ${({ theme }) => theme.smLaptop}) {
    width: 55%;
  }
`;

const FirstSectionText = styled.p`
  font-size: ${({ theme }) => theme.size14};
  font-weight: ${({ theme }) => theme.light};
`;

const FirstSectionUpperText = styled.p`
  font-size: ${({ theme }) => theme.size16};
  font-weight: ${({ theme }) => theme.medium};
  margin-bottom: 6px;
`;

const FirstSectionRightContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  .gatsby-image-wrapper {
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.25);
  }

  @media (min-width: ${({ theme }) => theme.smLaptop}) {
    display: block;
    width: 40%;
    margin-top: 0;
  }
`;

const Headline = styled.h1`
  font-size: ${({ theme }) => theme.size24};
  font-weight: ${({ theme }) => theme.semiBold};
  line-height: 130%;
  letter-spacing: 0%;
  margin-bottom: 20px;
`;

const Feature = styled.div`
  display: flex;
  align-items: center;
  min-height: 70px;

  &:last-of-type {
    margin-bottom: 30px;
  }

  p {
    font-size: ${({ theme }) => theme.size14};
    font-weight: ${({ theme }) => theme.light};

    @media (min-width: ${({ theme }) => theme.desktop}) {
      font-size: ${({ theme }) => theme.size15};
    }
  }

  @media (max-width: ${({ theme }) => theme.laptop}) {
    &:last-child {
      margin-bottom: 30px;
    }
  }
`;

const Icon = styled.div`
  width: 38px;
  min-width: 38px;
  height: 38px;
  background-color: ${({ theme }) => theme.yellow};
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SecondSection = styled.section`
  margin: 100px auto 100px auto;
  padding: 0 15px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
  }

  @media (min-width: ${({ theme }) => theme.desktop}) {
    padding: 0;
  }
`;

const SecondSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;

  @media (min-width: ${({ theme }) => theme.laptop}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const NumeralBox = styled.div`
  max-width: 365px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.laptop}) {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

const Number = styled.div`
  width: 45px;
  height: 45px !important;
  background-color: ${({ theme }) => theme.yellow};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
`;

const NumeralBoxText = styled.p`
  font-size: ${({ theme }) => theme.size14};
  font-weight: ${({ theme }) => theme.light};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.desktop}) {
    font-size: ${({ theme }) => theme.size15};
  }
`;

const ContactTextInfo = styled.p`
  font-size: ${({ theme }) => theme.size15};
  font-weight: ${({ theme }) => theme.medium};
  text-align: center;
  margin-bottom: 20px;

  @media (min-width: ${({ theme }) => theme.laptop}) {
    font-size: ${({ theme }) => theme.size16};
  }
`;

const SecondSectionTextBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
`;

const TextBlockBox = styled.div`
  width: 100%;
  /* background-color: #f9f9f9; */
  background-color: white;

  padding: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.04);

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const ApartmentInvestProduct = () => {
  const features = [
    'Indywidualne podejście',
    'Korzystna wycena dopasowana do oczekiwań właściciela mieszkania',
    'Mistrzowskie umowy sporządzone przez profesjonalistów z pewnością zabezpieczą Ciebie i Twoje mieszkanie',
  ];
  const numeralBoxes = [
    'Dzięki naszym działaniom zysk z wynajmu jest od 50% do 100% większy od zysków uzyskiwanych z mieszkań o identycznym metrażu w identycznej lokalizacji',
    'Zbudowanie portfela mieszkań na wynajem - zarabiaj pieniądze i żyj jak Rentier',
    'W razie potrzeby, możliwość szybkiej sprzedaży mieszkania - najszybciej i najlepiej sprzedają się tylko wyjątkowe produkty',
  ];
  const cms = useStaticQuery(query);
  return (
    <MainTemplate
      isMainPage={false}
      title='Stwórz z mieszkania produkt inwestycyjny i zarabiaj na najmie znacznie więcej'
      metaDescription='Stwórz z mieszkania produkt inwestycyjny i zarabiaj na najmie znacznie więcej.'
    >
      <SubpageHeader headline='Mieszkaniowy produkt inwestycyjny - zarabiaj na najmie znacznie więcej' />
      <FirstSection>
        <FirstSectionLeftContainer>
          <FirstSectionUpperText>
            {/* Co to jest mieszkaniowy produkt inwestycyjny? */}
            {cms.datoCmsApartmentInvestProductNewpage.firstSectionTitle}
          </FirstSectionUpperText>
          <FirstSectionText
            style={{ marginBottom: '15px' }}
            dangerouslySetInnerHTML={{
              __html: cms.datoCmsApartmentInvestProductNewpage.firstSectionText,
            }}
          />
          {/* Mieszkaniowy Produkt inwestycyjny jest to mieszkanie precyzyjnie
            dostosowane do potrzeb najmu . Pozwala wygenerować znacznie większy
            zysk niż przy standardowym wynajmie. Dostosowanie takiego produktu
            odbywa się już na etapie samego zakupu, poprzez wybór mieszkania
            spełniającego odpowiednie kryteria. Bardzo ważny jest również sam
            remont – w odpowiednim standardzie, dopasowany do potrzeb najemcy i
            nie przeszacowany finansowo. */}
          {/*  */}
          <FirstSectionUpperText>
            {/* Czym się zajmujemy? */}
            {cms.datoCmsApartmentInvestProductNewpage.firstSectionTitleSecond}
          </FirstSectionUpperText>
          <FirstSectionText
            dangerouslySetInnerHTML={{
              __html:
                cms.datoCmsApartmentInvestProductNewpage.firstSectionTextSecond,
            }}
          />
          {/* Zajmujemy się całym procesem, od zakupu nieruchomości po
            przygotowanie do wynajmu. Inwestujemy jedynie w produkty z
            potencjałem, głównie w mieszkaniowy rynek pierwotnym i kamienice z
            charakterem. Do każdej nieruchomości podchodzić indywidualnie, tylko
            takie podejście gwarantuje sukces. */}
        </FirstSectionLeftContainer>

        <FirstSectionRightContainer>
          <GatsbyImage image={cms.zarabianie.childImageSharp.gatsbyImageData} />
        </FirstSectionRightContainer>
      </FirstSection>
      <Roadmap cmsData={cms} />
      <FirstSection>
        <FirstSectionLeftContainer>
          <Headline>
            {/* Dla partnerów biznesowych oferujemy możliwość zarządzania najmem
            krótkoterminowym i długoterminowym */}
            {cms.datoCmsApartmentInvestProductNewpage.thirdSectionTitle}
          </Headline>

          {cms.datoCmsApartmentInvestProductNewpage.feature.map((edge, id) => (
            <Feature key={id}>
              <Icon>
                <svg
                  width='11'
                  height='19'
                  viewBox='0 0 11 19'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M0.381104 16.6288L1.9902 18.2379L10.9902 9.23792L1.9902 0.237915L0.381104 1.84701L7.77201 9.23792L0.381104 16.6288Z'
                    fill='white'
                  />
                </svg>
              </Icon>
              <p dangerouslySetInnerHTML={{ __html: edge.text }} />
            </Feature>
          ))}
          <ContactButton />
        </FirstSectionLeftContainer>

        <FirstSectionRightContainer>
          <GatsbyImage image={cms.hand.childImageSharp.gatsbyImageData} />
        </FirstSectionRightContainer>
      </FirstSection>
      <SecondSection>
        <Headline style={{ textAlign: 'center', maxWidth: '650px' }}>
          {/* Jaki zysk i możliwości generuje posiadanie mieszkaniowego produktu
          inwestycyjnego */}
          {cms.datoCmsApartmentInvestProductNewpage.fourthSectionTitle}
        </Headline>
        <SecondSectionContent>
          {cms.datoCmsApartmentInvestProductNewpage.apartmentInvestProductFeature.map(
            (edge, id) => (
              <NumeralBox>
                <Number>
                  <p>{id + 1}</p>
                </Number>
                <NumeralBoxText
                  dangerouslySetInnerHTML={{ __html: edge.text }}
                />
              </NumeralBox>
            )
          )}
        </SecondSectionContent>
        {/* <SecondSectionTextBlock>
          <Headline>Jak wygląda współpraca?</Headline>
          <TextBlockBox>
            <FirstSectionText>
              <strong>Na początku chcielibyśmy cię poznać</strong> i w tym celu
              zapraszamy na spotkanie. Tak aby najlepiej zbadać Twoje
              oczekiwania i najtrafniej ocenić Twoje potrzeby.
            </FirstSectionText>
          </TextBlockBox>
          <TextBlockBox>
            <FirstSectionText>
              <strong>Na spotkaniu</strong> chcielibyśmy zapoznać się z tym na
              czym najbardziej Ci zależy. Jaki kapitał chciałbyś zaangażować i
              czy masz czas na inwestowanie, a może raczej chciałbyś żebyśmy się
              tym zajęli za Ciebie.
            </FirstSectionText>
          </TextBlockBox>
          <TextBlockBox>
            <FirstSectionText>
              <strong>Sposób współpracy</strong>, w praktyce tworzymy dla Ciebie
              spersonalizowaną taktykę inwestycyjną. Wyszukamy dla Ciebie okazję
              inwestycyjną i zastępujemy Cię lub możemy zastąpić na każdym
              etapie procesu inwestycyjnego.
            </FirstSectionText>
          </TextBlockBox>

          <TextBlockBox>
            <FirstSectionText>
              <strong>Wsparcie zespołu specjalistów</strong> – dokonujemy
              analizę inwestycyjną zysków, prawnicy zadbają o opiekę prawną,
              profesjonalny zespół remontowy wraz ze wsparciem logistycznym i
              projektowym poprowadzi całość procesu remontowo-wykończeniowego.
            </FirstSectionText>
          </TextBlockBox>

          <TextBlockBox>
            <FirstSectionText>
              <strong>Gwarancja uzyskanych zysków</strong> – obsługując najem
              Twojej nieruchomości staramy się stale maksymalizować uzyskiwany
              przez Ciebie zysk. Twoje mieszkanie jest dobrze oceniane. Dzięki
              zawieranym profesjonalnym umowom najemcy znają swoje praw i
              obowiązki. Ekipy remontowe szybko naprawiają powstałe awarie, a
              zarządcy sprawują nadzór nad stanem wynajmowanej nieruchomości.
            </FirstSectionText>
          </TextBlockBox>

          <TextBlockBox>
            <FirstSectionText>
              <strong>Długotrwałe relacje</strong> – dbamy o relacje, dlatego
              będąc naszym partnerem zawsze możesz do Nas zadzwonić, zapytać o
              poradę, a My dołożymy wszelkich starań żeby Ci pomóc.
            </FirstSectionText>
          </TextBlockBox>
        </SecondSectionTextBlock> */}
        <HowCooperateWorks nonMargin={true} />
        {/* <ContactTextInfo>
          Skontaktuj się z nami, a odpowiemy na wszystkie Twoje pytania
        </ContactTextInfo>
        <ContactButton /> */}
      </SecondSection>
      <TheyWorkWithUs isSmall />
    </MainTemplate>
  );
};

const query = graphql`
  query ApartmentInvestProductQuery {
    hand: file(name: { eq: "ApartmentInvestImageFirst" }) {
      childImageSharp {
        gatsbyImageData(formats: WEBP, placeholder: NONE, quality: 100)
      }
    }
    zarabianie: file(name: { eq: "zarabianie" }) {
      childImageSharp {
        gatsbyImageData(formats: WEBP, placeholder: NONE, quality: 100)
      }
    }
    datoCmsApartmentInvestProductNewpage {
      firstSectionText
      firstSectionTitle
      firstSectionTitleSecond
      firstSectionTextSecond
      roadmapFifth
      roadmapFirst
      roadmapFourth
      roadmapSecond
      roadmapSeventh
      roadmapSixth
      roadmapThird
      roadmapTitle
      thirdSectionTitle
      fourthSectionTitle
      apartmentInvestProductFeature {
        text
      }
      feature {
        text
      }
    }
  }
`;

export default ApartmentInvestProduct;
